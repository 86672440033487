import React from 'react';
import starbackground from '../../images/star_background.png';
import '../../styles/Components/star_wrapper.scss';

const StarWrapper = () => (
  <div className="stars-wrapper">
    <img alt="star" className="stars stars-animation" src={starbackground} />
    <img alt="star" className="stars stars-animation-two" src={starbackground} />
    <img alt="star" className="stars stars-animation-three" src={starbackground} />
  </div>
);

export default StarWrapper;
