/* eslint-disable max-len */
import React, { useState, useContext, useEffect } from 'react';
import queryString from 'query-string';
import { navigate, Link, navigateTo } from 'gatsby';
import validate from 'validate.js';
import { ToastContainer, toast } from 'react-toastify';
import firebase from 'gatsby-plugin-firebase';
import Layout from '../layouts/index';
import SEO from '../components/seo';
import '../styles/Components/sign_in.scss';
import StarWrapper from '../components/Common/StarWrapper';
import RoundedInputField from '../components/Input/RoundedInputField';
import ValidStates from '../components/Input/ValidStates';
import DefaultButton from '../components/Input/DefaultButton';
import Model from '../models/user';
import { setCurrentCompanyId, setCurrentServiceId } from '../models/firebase';
import Onboarding from '../components/Common/Onboarding';
import LoadingSpinner from '../components/Common/LoadingSpinner';
const emailIcon = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.869492 5.71166L8.57533 1.09166C8.70484 1.01395 8.85304 0.9729 9.00408 0.9729C9.15511 0.9729 9.30331 1.01395 9.43283 1.09166L17.1312 5.71249C17.1929 5.7495 17.244 5.80189 17.2795 5.86455C17.315 5.9272 17.3337 5.99798 17.3337 6.06999V16.6667C17.3337 16.8877 17.2459 17.0996 17.0896 17.2559C16.9333 17.4122 16.7213 17.5 16.5003 17.5H1.50033C1.27931 17.5 1.06735 17.4122 0.91107 17.2559C0.75479 17.0996 0.666992 16.8877 0.666992 16.6667V6.06916C0.666977 5.99715 0.685624 5.92637 0.721113 5.86371C0.756603 5.80106 0.807725 5.74867 0.869492 5.71166ZM2.33366 6.77749V15.8333H15.667V6.77666L9.00366 2.77666L2.33366 6.77666V6.77749ZM9.05033 11.415L13.4637 7.69582L14.537 8.97082L9.06199 13.585L3.47033 8.97666L4.53033 7.68999L9.05033 11.415Z" fill="#CACFDB" />
  </svg>
);

const passwordIcon = (
  <svg width="20px" height="13px" viewBox="0 0 20 13" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="view-(1)" fill="#CACFDB" fillRule="nonzero">
        <path d="M19.8728516,6.01722656 C19.6941797,5.7728125 15.4370312,0.0326171875 9.99988281,0.0326171875 C4.56273438,0.0326171875 0.305390625,5.7728125 0.126914062,6.01699219 C-0.0423046875,6.24886719 -0.0423046875,6.56335938 0.126914062,6.79523438 C0.305390625,7.03964844 4.56273437,12.7798437 9.99988281,12.7798437 C15.4370312,12.7798437 19.6941797,7.03960938 19.8728516,6.79542969 C20.0423047,6.56359375 20.0423047,6.24886719 19.8728516,6.01722656 Z M9.99988281,11.4611719 C5.99484375,11.4611719 2.52605469,7.65128906 1.49921875,6.40578125 C2.52472656,5.15917969 5.98625,1.35128906 9.99988281,1.35128906 C14.0047266,1.35128906 17.4732812,5.16050781 18.5005469,6.40667969 C17.4750391,7.65324219 14.0135156,11.4611719 9.99988281,11.4611719 Z" id="Shape" />
        <path d="M9.99988281,2.45019531 C7.81855469,2.45019531 6.04382812,4.22492187 6.04382812,6.40625 C6.04382812,8.58757813 7.81855469,10.3623047 9.99988281,10.3623047 C12.1812109,10.3623047 13.9559375,8.58757813 13.9559375,6.40625 C13.9559375,4.22492187 12.1812109,2.45019531 9.99988281,2.45019531 Z M9.99988281,9.04359375 C8.54558594,9.04359375 7.36253906,7.86050781 7.36253906,6.40625 C7.36253906,4.95199219 8.545625,3.76890625 9.99988281,3.76890625 C11.4541406,3.76890625 12.6372266,4.95199219 12.6372266,6.40625 C12.6372266,7.86050781 11.4541797,9.04359375 9.99988281,9.04359375 Z" id="Shape" />
      </g>
    </g>
  </svg>
);
const model = new Model();
const SIGN_UP_ALLOWED_HASH = "2XCbOqwoFfVSwfOi9IsAlwOx4p";

const SignUp = () => {
  const [staticEmail, setStaticEmail] = useState('');
  const [disableEmail, setDisableEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [displayNotInvited, setDisplayNotInvited] = useState(false);
  const [startOnboarding, setStartOnboarding] = useState(false);
  const [inviteId, setInviteId] = useState('');
  const [values, setValues] = useState({
    email: {
      value: '',
      message: '',
      regex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
      state: ValidStates.NotSet,
    },
    password: {
      value: '',
      message: '',
      regex: /.+/g,
      state: ValidStates.NotSet,
    },
    confirmPassword: {
      value: '',
      message: '',
      regex: /.+/g,
      state: ValidStates.NotSet,
    },
  });
  (async () => {
    if (inviteId !== '' && inviteId !== undefined && staticEmail !== undefined && staticEmail !== '') {
      setValues({
        ...values,
        email: {
          value: staticEmail,
          state: ValidStates.Valid,
          message: '',
          regex: values.email.regex,
        }
      })
      setDisableEmail(true);
      firebase.auth().onAuthStateChanged(async (usr) => {
        if (usr) {
          setIsLoading(true);
          const userId = usr.uid;
          const firestore = firebase.firestore();
          const snapshot = firestore.doc(`users/${usr.uid}`).get();
          if (snapshot.exists) {
            // handle that
            const updateUser = await firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).update({
              email: staticEmail,
            });
          } else {
            const createNewUser = await firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).set({
              email: staticEmail,
            });
          }
          if (inviteId !== '' && inviteId !== undefined && staticEmail !== '') {
            const inviteRequest = await model.getInvite(inviteId);
            const companyId = inviteRequest.companyId;
            await setCurrentCompanyId(companyId);
            // Get Services from current company id
            const services = await model.getAllServices();
            // Use the first one to be current service
            const firstService = services[0];
            await setCurrentServiceId(firstService.id);
          }
          setStartOnboarding(true);
        }
        setIsLoading(false);
      });
      
      return;
    } else {
      const user = firebase.auth().currentUser;
      if (user) {
        navigate('/portal');
      }
      firebase.auth().onAuthStateChanged((usr) => {
        if (usr) {
          const firestore = firebase.firestore();
          firestore.doc(`users/${usr.uid}`).get()
            .then(docSnapshot => {
              if (docSnapshot.exists) {
                // do something
                const db = firestore.collection('users').doc(usr.uid).update({
                  email: usr.email,
                }).then(() => {
                  navigate('/portal');
                });
              } else {
                const db = firestore.collection('users').doc(usr.uid).set({
                  email: usr.email,
                }).then(() => {
                  navigate('/portal');
                });
              }
            });
        }
        setIsLoading(false);
      });
    }
  })()

  useEffect(() => {
    setIsLoading(true);
    const info = queryString.parse(window.location.search);
    if (!info.inviteCompanyId) {
      // not allowed here
      if (info.invite) {
        setStaticEmail(info.email || '');
        setInviteId(info.invite || '');
        // setDisplayNotInvited(false);
      } else {
        // navigate('/');
        // setDisplayNotInvited(true);
      }
    } else {
      const companyInvite = info.inviteCompanyId;
      if (companyInvite !== SIGN_UP_ALLOWED_HASH) {
        if (info.invite) {
          setStaticEmail(info.email || '');
          setInviteId(info.invite || '');
          // setDisplayNotInvited(false);
        } else {
          // navigate('/');
          // setDisplayNotInvited(true);
        }
      } else {
        // setDisplayNotInvited(false);
        setStaticEmail(info.email || '');
        setInviteId(info.invite || '');
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  }, []);

  const signUp = async () => {
    setIsLoading(true);
    try {
      const signUp = await firebase.auth().createUserWithEmailAndPassword(values.email.value, values.password.value);
    } catch (err) {
      if (err.code === 'auth/user-not-found') {
        toast.error('Sorry! This user was not found. Please Try again.', {
          position: toast.POSITION.TOP_LEFT
        });
        setIsLoading(false);
      }
      if (err.code === 'auth/email-already-in-use') {
        console.log(err.code);
        toast.error('Sorry! This email already exists. Please sign in or sign up using a different email.', {
          position: toast.POSITION.TOP_LEFT
        });
        setIsLoading(false);
      }
    }
  };

  const checkState = (e) => {
    if (e.currentTarget.id === 'email') {
      const errors = validate({ email: e.currentTarget.value }, {
        email: {
          length: {
            minimum: 5,
            maximum: 256,
          },
          email: {
            message: 'is not valid.',
          },
        },
      });
      if (errors !== undefined) {
        setValues({
          ...values,
          [e.currentTarget.id]: {
            value: e.currentTarget.value,
            state: ValidStates.Invalid,
            message: 'The field you have entered is incorrect. Please try again.',
            regex: values[e.currentTarget.id].regex,
          },
        });
        return;
      }
      setValues({
        ...values,
        [e.currentTarget.id]: {
          value: e.currentTarget.value, state: ValidStates.Valid, message: '', regex: values[e.currentTarget.id].regex,
        },
      });
    }
    const match = e.currentTarget.value.match(values[e.currentTarget.id].regex);
    if (match) {
      setValues({
        ...values,
        [e.currentTarget.id]: {
          value: e.currentTarget.value, state: ValidStates.Valid, message: '', regex: values[e.currentTarget.id].regex,
        },
      });
    } else {
      setValues({
        ...values,
        [e.currentTarget.id]: {
          value: e.currentTarget.value,
          state: ValidStates.Invalid,
          message: 'The field you have entered is incorrect. Please try again.',
          regex: values[e.currentTarget.id].regex,
        },
      });
    }
  };
  const updateValues = (event) => {
    checkState(event);
    setValues({
      ...values,
      [event.currentTarget.id]: {
        value: event.currentTarget.value,
        message: '',
        state: values[event.currentTarget.id].state,
        regex: values[event.currentTarget.id].regex,
      },
    });
  };

  const removeWhiteSpace = (e) => {
    const event = e || window.event;
    const modifiedEvent = event;
    modifiedEvent.currentTarget.value = event.currentTarget.value.replace(/\s/g, '');
    updateValues(modifiedEvent);
  };
  
  const onFormSubmit = (e) => {
    const event = e || window.event;
    if (typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    if (typeof event.stopPropagation === 'function') {
      event.stopPropagation();
    }
    event.returnValue = false;
    // Handle submission here
    signUp();
    return false;
  };

  const onSubmit = (e) => {
    const event = e || window.event;
    if (typeof event.preventDefault === 'function') {
      event.preventDefault();
    }
    if (typeof event.stopPropagation === 'function') {
      event.stopPropagation();
    }
    event.returnValue = false;

    return false;
  };

  const enabled = values.email.state === ValidStates.Valid && values.password.state === ValidStates.Valid && values.confirmPassword.value === values.password.value;
  const onboardingComplete = async (values) => {
    setIsLoading(true);
    firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).update({
      first_name: values.firstName.value,
      last_name: values.lastName.value,
      onboarded: true,
    }).then(async (success) => {
      // Send welcome email.
      try {
        await model.sendWelcomeEmail();
        const invite = await model.completeInvite(inviteId, firebase.auth().currentUser.uid);
        if (invite.invalid) {
          toast.error(invite.message, {
            position: toast.POSITION.TOP_LEFT
          });
        }
        setIsLoading(false);
        navigate('/portal');
      } catch (err){
        if (err) {
          toast.error('Sorry! There was an error completing your invitation.', {
            position: toast.POSITION.TOP_LEFT
          });
        }
      }
      
    })
  };
  return (
    <Layout>
      <SEO title="Sign In" />
      {startOnboarding && (
        <div className="portal-container">
          <Onboarding onCompletion={onboardingComplete} hasBeenInvited={inviteId !== '' && inviteId !== undefined} />
        </div>
      )}
      {!startOnboarding && (
        <>
          <StarWrapper />
          <section className="sign-in-container">
            <div className="sign-in-block">
              {!displayNotInvited && (
                <>
                  <h2>{inviteId !== '' && inviteId !== undefined && "You've been invited!"} {inviteId === '' && 'Sign Up'}</h2>
                  {inviteId && <p>Register your account here.</p>}
                  <form className="form-container" name="sign-in" onSubmit={onSubmit}>
                    <p>EMAIL</p>
                    <RoundedInputField
                      id="email"
                      disabled={disableEmail}
                      placeholder="Enter your email"
                      callback={e => removeWhiteSpace(e)}
                      checkState={checkState}
                      value={values.email.value}
                      icon={emailIcon}
                      state={values.email.state}
                      autoComplete="on"
                    />
                    <p>PASSWORD</p>
                    <RoundedInputField
                      id="password"
                      placeholder="Enter your password"
                      callback={e => removeWhiteSpace(e)}
                      checkState={checkState}
                      value={values.password.value}
                      icon={passwordIcon}
                      state={values.password.state}
                      autoComplete="on"
                      type="password"
                    />
                    <p>CONFIRM PASSWORD</p>
                    <RoundedInputField
                      id="confirmPassword"
                      placeholder="Enter your password"
                      callback={e => removeWhiteSpace(e)}
                      checkState={checkState}
                      value={values.confirmPassword.value}
                      icon={passwordIcon}
                      state={values.confirmPassword.state}
                      autoComplete="on"
                      type="password"
                    />
                    <DefaultButton
                      text="Sign Up"
                      callback={() => onFormSubmit()}
                      disabled={!enabled}
                      loading={isLoading}
                    />
                  </form>
                  <p>Already have an account? <Link to="/sign-in">Sign in here.</Link></p>
                </>
              )}
               {/* {displayNotInvited && (
                 <>
                  <h2>Looks like you haven't been invited to use Reach Beta!</h2>
                  <p className="centered-text">If you would like to request an invite, contact our team below.</p>
                  <div className="login-buttons">
                    <Link to="/">Go Back</Link>
                    <Link to="/contact">Contact Us</Link>
                  </div>
                </>
               )} */}
            </div>
          </section>
        </>
      )}
      <LoadingSpinner isLoading={isLoading} />
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
    </Layout>
  );
};

export default SignUp;